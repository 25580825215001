import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";
import "./productHeader.scss";

const getReactComponents = (rawData) => {
  if (rawData) {
    const richTextData = JSON.parse(rawData);
    const options = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      },
    };
    return documentToReactComponents(richTextData, options);
  }
};

export default function ProductHeader(props) {
  const productHeader = {
    productHeaderLeft: (
      props.productData &&(
      <div className="product-header__image">
        <img
          src={`https:${props.productData.productMainImage?.file.url}`}
          alt={props.productData.productMainImage?.description}
          loading="lazy"
        />
      </div>
    )
    ),
    productHeaderRight: (
      <>
    <div className="product-header-headline">
        <p>{props.productData.productCategory.categoryInnerTitle}</p>
        <h1>{props.productData.productInnerTitle}</h1>
      </div>
        <div className="product-header__description">
          {getReactComponents(props.productData.productDescription?.raw)}
        </div>
      </>
    ),
  };

  return (
    <div className="product-header">
      <div className="product-header-headline mobile">
        <p>{props.productData.productCategory.categoryInnerTitle}</p>
        <h1>{props.productData.productInnerTitle}</h1>
      </div>
      <TwoColumnsLayout
        leftContent={productHeader.productHeaderLeft}
        rightContent={productHeader.productHeaderRight}
      />
    </div>
  );
}
