import React from "react";
import "./ColorPalette.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

const getReactComponents = (rawData) => {
  if(rawData){
	const richTextData = JSON.parse(rawData);
	const options = {
		renderNode: {
			[BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
			[BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
		},
	};
	return documentToReactComponents(richTextData, options);
}
};

const ColorPalette = (props) => {
 
  return (
    <div className="palette">
   <div className="product-header-headline">
        <p>{props.colorData.productCategory.categoryInnerTitle}</p>
        <h1>{props.colorData.productInnerTitle}</h1>
      </div>
      <div className="product-header__description">
        {getReactComponents(props.colorData.productDescription?.raw)}
    </div>
      <div className="palette-container">
        {props.colorData.colorPalette.map((palette, index) => {
          return (
            <div className="color" key={index}>
              <div style={{ backgroundColor: `${palette.colorHexValue}` }}>
                {palette.colorSecondName}
              </div>
              <span>{palette.colorName}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ColorPalette;
