import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import TabComponent from "../../components/TabComponent/TabComponent";
import ProductHeader from "../../components/ProductHeader/ProductHeader";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import VideoGallery from "../../components/VideoGallery/VideoGallery";
import ColorPalette from "../../components/ColorPalette/ColorPalette";

export default function Product({ data }) {
  const currentCategoryData = data.allContentfulProduct.edges[0].node;


  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 300 && window.scrollY <= 2800) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);

  return (
    <Layout
      navData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.navigation
      }
      image={currentCategoryData.productMainImage.file.url}
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
      pageTitle={`${data.allContentfulProduct.edges[0].node
        .productInnerTitle
      } | Product`}
    >
      <div
        className={fixed ? "social-buttons fixed " : "social-buttons"}
        style={{ top: !fixed ? "200px" : "" }}
      >
        <SocialMedia
          socialMediaData={
            data.allContentfulWebPages.edges[0].node.pages[1].pageLayout
              .navigation.navigationPages[1].pageLayout.layoutMain[1]
              .buttonsContainer
          }
        />
      </div>
      {data.allContentfulProduct.edges[0].node
        .productBelongsToTheColorGalleryCategory ? (
        <ColorPalette colorData={data.allContentfulProduct.edges[0].node} />
      ) : (
        <>
          <ProductHeader
            productData={data.allContentfulProduct.edges[0].node}
          />
          <TabComponent
            tabsData={data.allContentfulProduct.edges[0].node.productTabs}
          />
        </>
      )}

      {/* <VideoGallery data={currentCategoryData.categoryVideos} /> */}
    </Layout>
  );
}
export const query = graphql`
query ProductQuery ($slug: String!) {
  allContentfulWebPages {
    edges {
      node {
        pages {
          pageLayout {
            navigation {
              navigationPages {
                pageTitle
                pageSlug
                pageLayout {
                  layoutMain {
                    ... on ContentfulContainer {
                      containerItems {
                        ... on ContentfulCategoryTeaser {
                          category {
                            categoryInnerTitle
                            categorySlug
                            categoryProducts {
                              product {
                                productInnerTitle
                                productSlug
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on ContentfulButtonsContainer {
                      buttonsContainer {
                        socialMediaLink
                        socialMediaTitle
                      }
                    }
                  }
                }
              }
              navigationImages {
                file {
                  url
                }
                description
              }
              navigationMediaIcons {
                socialMediaIcon {
                  file {
                    url
                  }
                  description
                }
                socialMediaTitle
                socialMediaLink
              }
            }
            footer {
              subscribeText
              subscribeDescription
              footerButtonText
              blockTitleFollowUs
              socialMediaLogosFooter {
                socialMediaIcon {
                  file {
                    url
                  }
                }
                socialMediaLink
              }
              followUsDescription
              blockTitleAddress
              blockInfoAddress
              blockTitleCallUs
              blockInfoCallUs
              blockTitleWriteUs
              blockInfoWriteUs
              blockTitleExplore
              footerPages {
                pageTitle
                pageSlug
              }
              copyrightText
              googleMapsAddress {
                googleMapsAddress
              }
            }
          }
        }
      }
    }
  }
  allContentfulProduct (filter: { productSlug: { eq: $slug } }) {
    edges {
      node {
        productName
        productInnerTitle
        productDescription {
          raw
        }
        productCategory {
          categoryInnerTitle
          categoryLongName
        }
        productTabs {
          productTabsBasicCharacteristicsTab {
            basicCharacteristicsTabName
            constructionTypologies
            constructionTypologiesDetails {
              raw
            }
            technicalFileName
            technicalFileNumberedDetailList {
              raw
            }
            technicalFileProperties {
              raw
            }
            technicalFileValues {
              raw
            }
            productCharacteristicsIcons {
              file {
                url
              }
              description
              title
            }
          }
          productTabsImagesTab {
            imagesTabName
            imagesTabStructureImages {
              file {
                url
              }
            }
            imagesTabProjectsName
            imagesTabStructureName
            imagesTabProjects {
              projectName
              projectText
              projectNameOfTheWindow
              projectYear
              projectImage {
                file {
                  url
                }
                description
              }
            }
          }
          productTabsFilesTab {
            filesTabInnerTitle
            filesTabName
            filesTabTechnicalFiles {
              file {
                fileName
                url
                contentType
              }
            }
          }
        }
        productMainImage {
          file {
            url
          }
          description
        }
        productBelongsToTheColorGalleryCategory
        colorPalette {
          colorHexValue
          colorName
          colorSecondName
        }
        categoryVideos {
          videosContainerSubHeader
          videosContainerHeader
          videosContainerSwipeText
          videosContainerVideosList {
            id
            youTubeLinkTitle
            youTubeVideoUrl
          }
        }
      }
    }
  }
}
`;
