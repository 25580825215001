import React, { useState } from "react";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";
import plus from "../../../static/assets/images/plus-circle.svg";
import minus from "../../../static/assets/images/minus.svg";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

//lighbox gallery
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "./tab.scss";

const getReactComponents = (rawData) => {
  const richTextData = JSON.parse(rawData);
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
  };
  return documentToReactComponents(richTextData, options);
};

function TabComponent(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpenProjects, setIsOpenProjects] = useState(false);
  const [photoIndexProjects, setPhotoIndexProjects] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setPhotoIndex(0);
  };

  const openLightboxProjects = (index) => {
    setPhotoIndexProjects(index);
    setIsOpenProjects(true);
  };

  const closeLightboxProject = () => {
    setIsOpenProjects(false);
    setPhotoIndexProjects(0);
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleDownloadClick = async (fileUrl) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const twoColumnLayoutContent = {
    leftContent: (
      <>
        {props.tabsData &&
        props.tabsData.productTabsBasicCharacteristicsTab
          .technicalFileProperties ? (
          <div className="basic-characteristics container-flex ">
            <div className="product-details container-flex  container-flex--justify-space-between  container-flex--align-center ">
              <div className="products-title">
                {getReactComponents(
                  props.tabsData.productTabsBasicCharacteristicsTab
                    .technicalFileProperties?.raw
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <h1 className="title">TECHNICAL FILES</h1>
            <p>- - - - - - - - - - </p>
          </>
        )}
      </>
    ),
    rightContent: (
      <>
        {props.tabsData && (
          <div className="products-list">
            {getReactComponents(
              props.tabsData.productTabsBasicCharacteristicsTab
                .technicalFileNumberedDetailList?.raw
            )}
          </div>
        )}
      </>
    ),
  };
  const twoColumnContent = {
    leftContent: (
      <>
        {props.tabsData?.productTabsBasicCharacteristicsTab
          .productCharacteristicsIcons && (
          <div className="icons container-flex ">
            {props.tabsData.productTabsBasicCharacteristicsTab.productCharacteristicsIcons.map(
              (icon, i) => (
                <div className="icon" key={i}>
                  <img src={`https:${icon?.file.url}`} alt={icon?.description} loading="lazy"/>
                  <h5>{icon.title}</h5>
                </div>
              )
            )}
          </div>
        )}
      </>
    ),
    rightContent: (
      <>
        {props.tabsData?.productTabsBasicCharacteristicsTab
          .constructionTypologiesDetails ? (
          <div className="construction-typologies">
            <h1>
              {
                props.tabsData?.productTabsBasicCharacteristicsTab
                  .constructionTypologies
              }
            </h1>
            {getReactComponents(
              props.tabsData?.productTabsBasicCharacteristicsTab
                .constructionTypologiesDetails.raw
            )}
          </div>
        ) : (
          <div className="construction-typologies">
            <h1>Construction Typologies</h1>
            <ul>- - - - - -</ul>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      {props.tabsData && (
        <div className="tab-container">
          <div className="tab-list">
            <button
              className={activeTab === 1 ? "active" : ""}
              onClick={() => handleTabClick(1)}
            >
              <h1>
                {
                  props.tabsData?.productTabsBasicCharacteristicsTab
                    .basicCharacteristicsTabName
                }
              </h1>
              <img
                src={activeTab === 1 ? minus : plus}
                className="show"
                alt={activeTab === 1 ? "minus" : "plus"}
                loading="lazy"
              />
            </button>
            <button
              className={activeTab === 2 ? "active" : ""}
              onClick={() => handleTabClick(2)}
            >
              <h1>{props.tabsData?.productTabsImagesTab.imagesTabName}</h1>
              <img
                src={activeTab === 2 ? minus : plus}
                className="show"
                alt={activeTab === 2 ? "minus" : "plus"}
                loading="lazy"
              />
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 1 && (
              <div className="fist-tab">
                <h1 className="title">
                  {
                    props.tabsData?.productTabsBasicCharacteristicsTab
                      .technicalFileName
                  }
                </h1>
                <TwoColumnsLayout
                  leftContent={twoColumnLayoutContent.leftContent}
                  rightContent={twoColumnLayoutContent.rightContent}
                  addedClass="products-content"
                />
                <TwoColumnsLayout
                  leftContent={twoColumnContent.leftContent}
                  rightContent={twoColumnContent.rightContent}
                  addedClass="products-construction"
                />
              </div>
            )}
            {activeTab === 2 && (
              <div className="second-tab">
                <div className="structure-container">
                  <h1>
                    {
                      props.tabsData?.productTabsImagesTab
                        .imagesTabStructureName
                    }
                  </h1>

                  <div className="image-container">
                    {props.tabsData?.productTabsImagesTab.imagesTabStructureImages.map(
                      (image, index) => (
                        <>
                          <img
                            key={index}
                            src={`https:${image?.file.url}`}
                            onClick={() => openLightbox(index)}
                            loading="lazy"
                          />
                        </>
                      )
                    )}
                    {isOpen && (
                      <Lightbox
                        slides={props.tabsData?.productTabsImagesTab.imagesTabStructureImages.map(
                          (image) => ({ src: `https:${image?.file.url}` })
                        )}
                        index={photoIndex}
                        close={closeLightbox}
                        open={isOpen}
                        plugins={[
                          Fullscreen,
                          Slideshow,
                          Thumbnails,
                          Zoom,
                          Counter,
                        ]}
                      />
                    )}
                  </div>
                </div>
                {props.tabsData?.productTabsImagesTab.imagesTabProjects && (
                  <div className="projects-container">
                    <h1>
                      {
                        props.tabsData?.productTabsImagesTab
                          .imagesTabProjectsName
                      }
                    </h1>
                    <div className="image-container">
                      {props.tabsData?.productTabsImagesTab.imagesTabProjects?.map(
                        (project, index) => (
                          <>
                            <img
                              key={index}
                              src={`https:${project.projectImage.file.url}`}
                              alt={project.projectImage.description}
                              onClick={() => openLightboxProjects(index)}
                              loading="lazy"
                            />
                          </>
                        )
                      )}
                      {isOpenProjects && (
                        <Lightbox
                          slides={props.tabsData?.productTabsImagesTab.imagesTabProjects?.map(
                            (project) => ({ src: `https:${project?.projectImage.file.url}` })
                          )}
                          index={photoIndexProjects}
                          close={closeLightboxProject}
                          open={isOpenProjects}
                          plugins={[
                            Fullscreen,
                            Slideshow,
                            Thumbnails,
                            Zoom,
                            Counter,
                          ]}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default TabComponent;
